


export const Footer = () => {

    return (
          <footer>
                <div className='footerText'>© 2023 Chaud de l'Eau</div>
            </footer> 
    )
}