
import {useEffect} from 'react'
import { Navbar } from './Navbar'
import skimmer from '../assets/skimmer.jpg'
import overflow from '../assets/overflow.jpg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";
import { Footer } from './Footer'
import { Helmet } from 'react-helmet-async'



const Landing = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return (
        <>        
         <Helmet>
            <title>Chaud de l'Eau | Skimmer & Overflow pools!</title>
                <meta
                name="description"
                content="Chaud de l'Eau supplies your custom made swimming pools (SKIMMER & OVERFLOW)"
                />
            </Helmet>
        <div className='containerLanding'>        
            <main>
                <section className='firstLandingPart'>
                    <Navbar typeN="home"/>
                    <div className='infotext'>
                        <div className='backcolortext'>Skimmer & Overflow Pools</div>
                        <div className='textBig'>Swimming Pools made<br/>for you.</div>
                    </div>
                    <div className='scrolldown'>
                        <a href='#pools' className='scrolldownText'>CHECK OUR CATALOGUE</a>
                        <div className='spacel'></div>
                    </div>
                </section>
                <section id='pools' className='poolsPart'>
                    <div className='card skimmer' data-aos="fade-right">
                        <img src={skimmer} alt="skimmer pool"/>
                        <div className='infoCard'>
                            <div className='titleCard'>Skimmer pools</div>
                            <div className='bigTitleCard'>Customization & quality </div>
                            <div className='textCard'>A compact swimming pool design that typically measures around 50 feet in length. It features a skimmer system, which is an essential component for maintaining the pool's cleanliness.</div>
                            <div className='colorOptions'><div className='white'></div><div className='grey'></div><div className='darkgrey'></div><div className='yellow'></div><div className='blue'></div></div>
                            <Link to='/detail/skimmer'><button className='goButton'>CHECK &rarr;</button></Link>
                        </div>
                    </div>   
                    <div className='card overload  top ' data-aos="fade-left">
                        
                        <div className='infoCard'>
                            <div className='titleCard'>Overflow pools</div>
                            <div className='bigTitleCard'>Customization & quality </div>
                            <div className='textCard'>A vanishing edge or infinity pool, is a luxurious pool design that creates the illusion of water flowing endlessly into the horizon. It features one or more edges where the water spills over, creating a beautiful waterfall effect.</div>
                            <div className='colorOptions'><div className='white'></div><div className='grey'></div><div className='darkgrey'></div><div className='yellow'></div><div className='blue'></div></div>
                            <Link to='/detail/overflow'>  <button className='goButton'>CHECK &rarr;</button></Link>
                        </div>
                        <img src={overflow} alt="overflow pool"/>
                    </div>   
                </section>
            </main>
            <Footer/>
        </div>
        </>
    )
}

export default Landing