
import React from 'react';
import {FiMail} from 'react-icons/fi'
import logowhite from '../assets/whitelogo.png'
import { Link } from 'react-router-dom';

export const Navbar = ({typeN}) => {

    return (
            <nav className={typeN === 'home' ? 'tran': 'blueBack'}>
                <div className='logomobile'>
                <Link to="/home">  <img src={logowhite} alt='logowhite'/></Link>
               

                </div>
                <div className='logo'>
                   <Link to="/home"> <img src={logowhite} className='logowh' alt='logowh'></img></Link>
                </div>
                <Link to='/contact'>
                <div className='contactButton'>
                    <button><FiMail className='iconcontact'/><span>CONTACT</span></button>
                </div>
                </Link>
            </nav>
    )
}