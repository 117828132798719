import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
// import { NotFound } from "./components/NotFound";
import Landing from "./components/Landing";
import Detail from "./components/Detail";
import Contact from "./components/Contact";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        {<Route path="*" element={<Landing />} />}
        <Route path="/home" element={<Landing />} />
        <Route path="/detail/:id" element={<Detail />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
