
import {useEffect} from 'react'
import { Navbar } from './Navbar'
import { useParams } from 'react-router-dom'
import  Skim  from '../assets/skimmer_pool.jpg'
import  Over  from '../assets/overflow_pool.jpg'
import { Link } from 'react-router-dom'
import { Footer } from './Footer'
import { Helmet } from 'react-helmet-async'


const Detail = () => {
    const { id } = useParams();
    const typedata = id === 'skimmer' ? 'skimmer' : 'overflow'
    const data = {
        'skimmer': {
            'title': 'Skimmer pools',
            'specifications': ['Pool floor + walls made of 8mm PP panels (Röchling PGX)', "Pool body reinforced by PP ribs + steel reinforcement", 'Pre-assembled XPS20/40 Styrodur insulation','Pre-assembled piping including Tubex insulation','Steel bars for easy manipulation with the crane','Stable wooden frame + protective film','Pre-assembled components'],
            'colors': ['white','grey','darkgrey','yellow','blue'],
            'dimensions':["Length: 12.4m", 'Width: 4m','Depth: 1.5m'],
        },
        'overflow': {
            'title': 'Overflow pools',
            'specifications': ['Pool floor + walls made of 8mm PP panels (Röchling PGX)', "Pool body reinforced by PP ribs + steel reinforcement", 'Pre-assembled XPS20/40 Styrodur insulation','Pre-assembled piping including Tubex insulation','Steel bars for easy manipulation with the crane','Stable wooden frame + protective film','pre-assembled built-in parts','Pre-assembled components','Cover of the overflow channel in various designs'],
            'colors': ['white','grey','darkgrey','yellow','blue'],
            'dimensions':["Length: 12.4m", 'Width: 4m','Depth: 1.5m'],
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
        <Helmet>
            <title>Chaud de l'Eau | {typedata === "overflow" ? "Overflow pools" : "Skimmer pools" }</title>
                <meta
                name="description"
                content={"Information about our " + typedata === "overflow" ? "Overflow pools" : "Skimmer pools"}
                />
            </Helmet>
        <div className='containerDetail'>        
            <main>
                <section className='detailImgPart'>
                    <Navbar typeN={'home'}/>
                    <div className='titleDetail'>{data[typedata].title}</div>
                </section>
                <section className='detailInfoPart'>
                    <div className='information'>
                    <div className='type'>
                        <div className='titleCard'>Type</div>
                        <div className='textType'>{data[typedata].title}</div>
                    </div>
                    <hr/>
                    <div className='specifications'>
                        <div className='titleCard'>Specifications</div>
                        <div className='text'>
                            {data[typedata].specifications.map((specification, index) => {
                                return <li key={index}>{specification}</li>
                            })}
                        </div>
                    </div>
                    <div className='colors'>
                        <div className='titleCard'>Colours</div>
                        <div className='colorOptions'>
                            {data[typedata].colors.map((color, index) => {
                                return <div key={index} className={color}></div>
                            })}
                        </div>
                    </div>

                    <div className='dimensions'>
                        <div className='titleCard'>Dimensions</div>
                        <div className='text'>
                            {data[typedata].dimensions.map((dimension, index) => {
                                return <li key={index}>{dimension}</li>
                            })}
                        </div>
                    </div>
                    </div>
                    <div className='image'>
                        <img className='imgPool' src={typedata === 'skimmer' ? Skim : Over} alt="skimmer pool"/>
                    </div>

                </section>
                <Link to="/contact" className='extrabut'>
                    <button className='buttonCont'>Contact us for more information</button>
                </Link>
            </main>
            <Footer />
        </div>
        </>
    )
}

export default Detail